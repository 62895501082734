import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

if (process.env.NODE_ENV !== 'development') {
  console.log(
    '%cFEH Journal 2.0',
    'color:red; font-size: 1.5rem; font-family: monospace, sans-serif',
  );
  console.log('Found a bug? 🐛');
  console.log('Want a new feature? 🚀');
  console.log('Interested in how this web app works? 👩🏽‍💻');
  console.log('> Email me at steadman.21@osu.edu');

  console.log(
    '%cMake sure not to paste any code here unless you understand it.',
    'font-weight: bold',
  );
}
