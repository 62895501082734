import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Prompt from '../views/Prompt.vue';
import AuthCallback from '../views/AuthCallback.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/journal/:journalId',
    name: 'Journal Prompt',
    component: Prompt,
    props: true,
  },
  {
    path: '/instructor',
    name: 'Instructor Panel',
    component: () => import(/* webpackChunkName: "inst" */ '../views/Instructor.vue'),
  },
  {
    path: '/instructor/responses/:journalId/:enrollmentId',
    name: 'Journal Responses',
    component: () => import(/* webpackChunkName: "resp" */ '../views/Responses.vue'),
    props: true,
  },
  {
    path: '/instructor/responses/:journalId',
    name: 'Section Selections',
    component: () => import(/* webpackChunkName: "sect" */ '../views/SelectSection.vue'),
    props: true,
  },
  {
    path: '/admin',
    name: 'Admin Panel',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
  },
  {
    path: '/auth/callback',
    name: 'Logging in...',
    component: AuthCallback,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(to => {
  if (document.title) {
    document.title = to.name ? `${to.name} | FEH Journal System` : 'FEH Journal System';
  }
});

export default router;
