import Vue, { VueConstructor } from 'vue';
import { Store } from 'vuex';
import { defaultState } from './index';

abstract class VueWithStoreClass extends Vue {
  public $store!: Store<typeof defaultState>;
}
const VueWithStore = Vue as VueConstructor<VueWithStoreClass>;

export default VueWithStore;
