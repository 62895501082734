
import { Component, Prop } from 'vue-property-decorator';
import VueWithStore from '@/store/classTypes';

@Component
export default class TrueFalse extends VueWithStore {
  isTrue: boolean | null = null;

  @Prop({ type: String }) question!: string;

  @Prop({ type: String }) id!: string;

  @Prop({ type: Boolean }) readOnly!: boolean;

  mounted() {
    if (!this.readOnly) {
      this.$store.commit('revivePromptResponse', {
        promptId: this.id,
        key: 'isTrue',
      });
    }

    const revivedisTrue = this.$store.getters.promptResponse(this.id, 'isTrue');
    if (typeof revivedisTrue === 'boolean') {
      this.isTrue = revivedisTrue;
    }
  }

  setisTrue(isTrue: boolean) {
    if (this.readOnly) return;

    this.isTrue = isTrue;

    this.$store.commit('setPromptResponse', {
      promptId: this.id,
      key: 'isTrue',
      value: isTrue,
    });
  }
}
