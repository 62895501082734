
import { Component, Prop } from 'vue-property-decorator';
import VueWithStore from '@/store/classTypes';

@Component
export default class MultiChoice extends VueWithStore {
  selections: Record<string, boolean> = {};

  @Prop({ type: Array }) options!: string[];

  @Prop({ type: String }) question!: string;

  @Prop({ type: String }) id!: string;

  @Prop({ type: Boolean }) allowMany!: boolean;

  @Prop({ type: Boolean }) readOnly!: boolean;

  mounted() {
    if (!this.readOnly) {
      this.$store.commit('revivePromptResponse', {
        promptId: this.id,
        key: 'selections',
      });
    }

    const revivedSelections = this.$store.getters.promptResponse(this.id, 'selections');
    if (revivedSelections) {
      this.$set(this, 'selections', { ...this.selections, ...revivedSelections });
    }
  }

  selectOpt(optId: string) {
    if (this.readOnly) return;

    let selections = { ...this.selections };
    this.options.forEach(b => {
      if (b === optId) {
        selections = { ...selections, [b]: !selections[b] };
      } else if (!this.allowMany) {
        selections = { ...selections, [b]: false };
      }
    });
    this.$set(this, 'selections', selections);

    this.$store.commit('setPromptResponse', {
      promptId: this.id,
      key: 'selections',
      value: selections,
    });
  }
}
