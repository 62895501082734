
import { Component, Vue } from 'vue-property-decorator';
import { reauthenticate, TOKENS } from '@/shared/auth';
import { get } from '@/shared/requests';
import Store from '@/shared/store';

@Component({})
export default class Home extends Vue {
  loading = true;

  error = '';

  journals: {
    name: string;
    id: string;
    dueAt: number;
  }[] = [];

  completions: Record<
    string,
    {
      journalId: string;
      id: string;
      createdAt: number;
    }
  > = {};

  timeRegex = /:\d{2}(?=[^:])/;

  mounted() {
    if (TOKENS.LOGGEDIN) {
      const roles = Store.namespace('auth').get('roles') ?? [];

      if (roles.indexOf('admin') !== -1 && roles.indexOf('student') === -1) {
        this.$router.push('/admin');
        return;
      }

      if (roles.indexOf('instructor') !== -1 && roles.indexOf('student') === -1) {
        this.$router.push('/instructor');
        return;
      }

      this.fetchJournals();
    } else {
      reauthenticate('/');
    }
  }

  async fetchJournals() {
    const { success, error, data, status } = await get('/student/journals');

    if (success && data) {
      this.$set(this, 'loading', false);
      this.$set(
        this,
        'journals',
        data.journals.sort((a: { dueAt: number }, b: { dueAt: number }) => a.dueAt < b.dueAt),
      );
      this.$set(
        this,
        'completions',
        (data.completions as typeof Home.prototype.completions[0][]).reduce((a, b) => {
          a[b.journalId] = b;
          return a;
        }, {} as typeof Home.prototype.completions),
      );
    } else if (error) {
      console.error(error);
      this.$set(this, 'loading', false);
      this.$set(this, 'error', 'Unable to get your journals.');

      if (status === 401) {
        reauthenticate('/');
      }
    }
  }
}
