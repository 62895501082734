
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationError, extractHashCredentials, reauthenticate } from '@/shared/auth';
import { get } from '@/shared/requests';
import Store from '@/shared/store';

@Component({})
export default class AuthCallback extends Vue {
  error = '';

  errorExtraContext = '';

  async mounted() {
    try {
      extractHashCredentials();
    } catch (error) {
      const err = error as AuthenticationError;
      console.error(err);

      if (err.type === 'auth') {
        this.$set(this, 'error', err.message);
        if (err.additionalContext) this.$set(this, 'errorExtraContext', err.additionalContext);

        if (err.action === 'retry') {
          setTimeout(() => reauthenticate(this.$router.currentRoute.path), 3000);
        }
      } else {
        this.$set(this, 'error', 'There was an issue logging you in. Try a different browser.');
      }
    }

    if (!this.error) {
      const { data, success, error } = await get('/auth/role');

      if (success) {
        const priorRoute = Store.namespace('auth').get('postSignInPath');

        Store.namespace('auth').remove('postSignInPath');

        if (priorRoute && priorRoute !== '/' && priorRoute.indexOf('/auth') !== 0) {
          this.$router.push(priorRoute);
          return;
        }

        if (data.role === 'instructor' && data.isAdmin) {
          Store.namespace('auth').set('roles', ['admin', 'instructor']);
          this.$bvModal.show('admin-view');
        } else if (data.role === 'instructor') {
          Store.namespace('auth').set('roles', ['instructor']);
          this.$router.push('/instructor');
        } else {
          Store.namespace('auth').set('roles', ['student']);
          this.$router.push('/');
        }
      } else {
        console.error(error);
        Store.clearAll();
        this.$set(this, 'error', error.message);
        this.$set(this, 'errorExtraContext', 'Authentication role error prohibited sign in.');
      }
    }
  }

  async redirect(to: string) {
    this.$bvModal.hide('admin-view');
    this.$router.push(to);
  }
}
